import axios from 'axios';
import env from '../utils/env';

export const api = axios.create({
  timeout: 0,
  baseURL: env.apiUrl + "wp-json/api/v1/gt/",
});

export const apiFireBase = axios.create({
  timeout: 0,
  baseURL: "https://fcm.googleapis.com/fcm"
});

export const apiJobRunner = axios.create({
  timeout: 0,
  baseURL: env.jobRunnerUrl
});
