import { api } from "../../utils/api";
import { LoginType, UserAuthType } from "../../types/auth/AuthType";
import { UserModulesType } from "../../types/users/UserModulesType";

class AuthService {
  public check(): boolean {
    const userAuth = localStorage.getItem("auth");
    return userAuth !== null;
  }

  public logout(): void {
    localStorage.removeItem("auth");
    window.location.replace("/");
  }

  public user(): UserAuthType | null {
    const user = localStorage.getItem("auth");
    return user ? JSON.parse(user) as UserAuthType : null;
  }

  public async login(dataLogin: LoginType): Promise<void> {
    try {
      const { data } = await api.post("/loginJwt", dataLogin);
      const userAuth: UserAuthType = {
        email: dataLogin.login,
        type: data.data.user.tipo ?? "",
        id: data.data.user.id,
        foto: data.data.user.foto ?? "",
        token: data.data["jwt-auth"].token
      }

      if (data.data.user.permissoes) {
        const userModules = JSON.parse((data.data.user.permissoes as string).replace(/\\/g, ""));
        userAuth.permissoes = userModules as UserModulesType[];
      }

      localStorage.setItem("auth", JSON.stringify(userAuth));
    } catch (error) {
      throw new Error("USUÁRIO OU SENHA INVÁLIDOS.");
    }
  }
}

export default new AuthService();
