import { MenuRouterType } from "../../types/menu-routes/MenuRouterType";

const menuRoutes: Array<MenuRouterType> = [
  {
    title: "DASHBOARD",
    href: "/dashboard"
  },
  {
    title: "CADASTROS",
    children: [
      {
        title: "GUIAS",
        href: "/cadastros/guia"
      },
      {
        title: "DESTINOS",
        href: "/cadastros/destino"
      },
      {
        title: "PASSEIOS",
        href: "/cadastros/passeios"
      },
      {
        title: "TRASLADOS",
        href: "/cadastros/traslados"
      },
      {
        title: "ASSOCIAÇÕES",
        href: "/cadastros/associacoes"
      },
      {
        title: "USUÁRIOS",
        href: "/cadastros/usuarios"
      },
      {
        title: "CATEGORIA DE VEÍCULOS",
        href: "/cadastros/categoria_veiculos"
      },
      {
        title: "DESTAQUES",
        href: "/cadastros/destaques"
      }
    ]
  },
  {
    title: "MONITORAMENTO",
    children: [
      {
        title: "O.S DE PASSEIOS",
        href: "/monitoramento/os_passeio"
      },
      {
        title: "O.S DE TRASLADOS",
        href: "/monitoramento/os_traslado"
      },
      {
        title: "ATIVIDADES APPGUIA",
        href: "/monitoramento/atividade"
      },
      {
        title: "ATIVIDADES GUIATRANSFER",
        href: "/monitoramento/atividade_guiatransfer"
      },
      {
        title: "GESTÃO DE RESERVAS",
        href: "/monitoramento/reserva"
      }
    ]
  },
  {
    title: "VENDAS",
    children: [
      {
        title: "RESERVAS",
        href: "/venda/reserva"
      },
      {
        title: "TRANSAÇÕES",
        href: "/venda/transacoes"
      },
      {
        title: "CANCELAMENTOS",
        href: "/venda/cancelamento"
      },
      {
        title: "LISTAGEM ANTIFRAUDE",
        href: "/venda/listagem-antifraude"
      },
      {
        title: "CUPONS",
        href: "/venda/cupom"
      },
      {
        title: "PESQUISA DE SATISFAÇÃO",
        href: "/venda/pesquisa_satisfacao"
      },
      {
        title: "AVALIAÇÃO",
        href: "/venda/avaliacao"
      }
    ]
  },
  {
    title: "FINANCEIRO",
    children: [
      {
        title: "MERCADO PAGO",
        target: "_blank",
        href: "https://www.mercadopago.com.br"
      },
      {
        title: "VHSYS",
        target: "_blank",
        href: "https://vhsys.com.br"
      },
      {
        title: "RELATÓRIOS",
        href: "/financeiro/relatorio"
      }
    ]
  }
  // {
  //   title: "CONFIGURAÇÕES",
  //   children: [
  //     {
  //       title: "MARKETPLACE",
  //       href: "/configuracoes/marketplace"
  //     },
  //     {
  //       title: "INFORMATIVOS",
  //       href: "/configuracoes/informativos"
  //     },
  //     {
  //       title: "GALERIA FOTO",
  //       href: "/configuracoes/galeria_foto"
  //     },
  //   ],
  // },
];

export default menuRoutes;
