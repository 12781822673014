import { lazy } from "react";
import RouterType from "../types/router/RouterType";

const Dashboard = lazy(() => import("../Page/dashboard/Dashboard"));

//Monitoramentos

const ReserveManagement = lazy(() =>
  import("../Page/monitoramento/reservemManagement/ReserveManagement")
);
const FormManagementReserve = lazy(() =>
  import("../Page/monitoramento/reservemManagement/components/FormManagementReserve")
);

const Activities = lazy(() =>
  import("../Page/monitoramento/activities/Activities")
);
const GuideActivity = lazy(() =>
  import("../Page/monitoramento/guideActivity/GuideActivity")
);
const OsTour = lazy(() => import("../Page/monitoramento/osTour/OsTour"));
const OsTraslado = lazy(() =>
  import("../Page/monitoramento/osTraslado/OsTraslado")
);

//Cadastro
const Guide = lazy(() => import("../Page/registered/guide/Guide"));
const GuidesDelete = lazy(() =>
  import("../Page/registered/guide/GuidesDelete")
);

const Destiny = lazy(() => import("../Page/registered/destiny/Destiny"));
const FormDestiny = lazy(() =>
  import("../Page/registered/destiny/components/FormDestiny")
);

const Tour = lazy(() => import("../Page/registered/tour/Tour"));
const FormTour = lazy(() =>
  import("../Page/registered/tour/components/FormTour")
);

const Traslado = lazy(() =>
  import("../Page/registered/traslado/Traslado")
);
const FormTraslado = lazy(() =>
  import("../Page/registered/traslado/components/FormTraslado")
);

const Association = lazy(() =>
  import("../Page/registered/association/Association")
);
const FormAssociation = lazy(() =>
  import("../Page/registered/association/components/FormAssociation")
);

const User = lazy(() => import("../Page/registered/user/User"));
const VehicleCategory = lazy(() =>
  import("../Page/registered/vehicleCategory/VehicleCategory")
);
const FormVehicleCategory = lazy(() =>
  import("../Page/registered/vehicleCategory/components/FormVehicleCategory")
);
const DestaquesCadastro = lazy(() =>
  import("../Page/registered/destaques/DestaquesCadastro")
);

//Vendas
const Reserves = lazy(() => import("../Page/sales/reserves/Reserves"));
const Transactions = lazy(() =>
  import("../Page/sales/transactions/Transactions")
);
const Cancellations = lazy(() =>
  import("../Page/sales/cancellations/Cancellations")
);
const SatisfactionSurvey = lazy(() =>
  import("../Page/sales/satisfactionSurvey/SatisfactionSurvey")
);

const GuiaStepForm = lazy(() =>
  import("../Page/registered/guide/components/Form/GuiaStepForm")
);

const FormGuideActivity = lazy(() =>
  import("../Page/monitoramento/guideActivity/components/FormGuideActivity")
);

const FormUser = lazy(() =>
  import("../Page/registered/user/components/FormUser")
);

const AntiFraudList = lazy(() =>
  import("../Page/sales/antifraude/AntiFraudList")
);

const routes: RouterType[] = [
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/cadastros/guia",
    component: Guide
  },
  {
    path: "/cadastros/form/guia/:id?",
    component: GuiaStepForm
  },
  {
    path: "/cadastros/guias-deletar",
    component: GuidesDelete
  },
  {
    path: "/cadastros/destino",
    component: Destiny
  },
  {
    path: "/cadastros/form/destino/:id?",
    component: FormDestiny
  },
  {
    path: "/cadastros/passeios",
    component: Tour
  },
  {
    path: "/cadastros/form/passeios/:id?",
    component: FormTour
  },
  {
    path: "/cadastros/traslados",
    component: Traslado
  },
  {
    path: "/cadastros/form/traslados/:id?",
    component: FormTraslado
  },
  {
    path: "/cadastros/associacoes",
    component: Association
  },
  {
    path: "/cadastros/form/associacoes/:id?",
    component: FormAssociation
  },
  {
    path: "/cadastros/usuarios",
    component: User
  },
  {
    path: "/cadastros/form/usuarios/:id?",
    component: FormUser
  },
  {
    path: "/cadastros/categoria_veiculos",
    component: VehicleCategory
  },
  {
    path: "/cadastros/form/categoria_veiculos/:id?",
    component: FormVehicleCategory
  },
  {
    path: "/cadastros/destaques",
    component: DestaquesCadastro
  },
  {
    path: "/monitoramento/reserva",
    component: ReserveManagement
  },
  {
    path: "/monitoramento/form/reserva",
    component: FormManagementReserve
  },
  {
    path: "/venda/reserva",
    component: Reserves
  },
  {
    path: "/venda/transacoes",
    component: Transactions
  },
  {
    path: "/venda/cancelamento",
    component: Cancellations
  },
  {
    path: "/venda/pesquisa_satisfacao",
    component: SatisfactionSurvey
  },
  {
    path: "/venda/listagem-antifraude",
    component: AntiFraudList
  },
  {
    path: "/monitoramento/os_passeio",
    component: OsTour
  },
  {
    path: "/monitoramento/os_traslado",
    component: OsTraslado
  },
  {
    path: "/monitoramento/atividade",
    component: Activities
  },
  {
    path: "/monitoramento/atividade_guiatransfer",
    component: GuideActivity
  },
  {
    path: "/monitoramento/form/atividade_guiatransfer/:id?",
    component: FormGuideActivity
  }
];

export default routes;
