import React from "react";
import './button.css'

import {Button} from "react-bootstrap";

const ButtonIC = (props: any) => {

  return (
    <Button variant={props.variant}
      className={'ButtonIC ' + props.className}
      disabled={props.disabled}
      href={props.href}
      style={props.style}
      onClick={props.onClick} type={"submit"}>
      {props.children}
    </Button>
  );
}
//
// ButtonIC.propTypes  = {
//     variant: PropTypes.oneOf([ 'primary' , 'secondary' , 'success',  'danger' ,  'warning',  'info',  'dark',  'light',  'link']),
//     routerComponent: PropTypes.element,
//     href: PropTypes.string,
//     onClick: PropTypes.func,
//     style: PropTypes.string
// };

export default ButtonIC
