import React from "react";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";

// ICONS
import { VscHome } from "react-icons/vsc";
import { MdAttachMoney } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { FaMoneyCheckAlt } from "react-icons/fa";

import menuRoutes from "../../assets/js/menu-router";
import {
  ChildrenMenuRouterType,
  MenuRouterType,
} from "../../types/menu-routes/MenuRouterType";
import { BiCamera } from "react-icons/bi";
import AuthService from "../../services/auth/AuthService";
import { ChildrenUserModulesType, UserModulesType } from "../../types/users/UserModulesType";

const Sidebar: React.FC = () => {
  const getIcon = (menu: string) => {
    const menus = {
      DASHBOARD: <VscHome className="icon_menu_navbar" />,
      CADASTROS: <FiPlus className="icon_menu_navbar" />,
      MONITORAMENTO: <BiCamera className="icon_menu_navbar" />,
      VENDAS: <FaMoneyCheckAlt className="icon_menu_navbar" />,
      FINANCEIRO: <MdAttachMoney className="icon_menu_navbar" />,
      // CONFIGURAÇÕES: <VscSettingsGear className="icon_menu_navbar" />,
    };

    return menus[menu] ?? <VscHome className="icon_menu_navbar" />
  };

  const user = AuthService.user();
  const permissions = user?.permissoes?.map((item: UserModulesType) => item.title) ?? [];

  const filterRoutes = (itemMenu: MenuRouterType): boolean => {
    if (user?.type.includes("painel")) {
      return true;
    }
    return user !== null && permissions.includes(itemMenu.title);
  }

  const filterChildren = (children: ChildrenMenuRouterType, itemMenu: MenuRouterType): boolean => {
    if (user?.type.includes("painel")) {
      return true;
    } else {
      const menu = user?.permissoes?.find((item: UserModulesType) => item.title === itemMenu.title);
      if (menu) {
        const childrenMenu = menu.children.find(
          (item: ChildrenUserModulesType) => item.title === children.title);
        return childrenMenu !== undefined;
      }
      return false;
    }
  }

  return (
    <Box
      sx={{ overflow: "auto" }}
      className="mt-1"
    >
      {menuRoutes.filter(filterRoutes).map((itemMenu: MenuRouterType) => (
        itemMenu.href ? (
          <NavLink
            to={{ pathname: itemMenu.href }}
            key={itemMenu.href + itemMenu.title}
            className={`item_menu_navbar`}
          >
            {itemMenu.title} {getIcon(itemMenu.title)}
          </NavLink>
        ) : (
          <div
            key={itemMenu.href + itemMenu.title}
            className={`
            item_menu_navbar
            ${itemMenu.children ? "" : " cursor_pointer "}
          `}
          >
            {itemMenu.title} {getIcon(itemMenu.title)}
            {itemMenu.children &&
              itemMenu.children
                .filter((value: ChildrenMenuRouterType) => filterChildren(value, itemMenu))
                .map((item: ChildrenMenuRouterType) => (
                  <NavLink
                    to={{ pathname: item.href }}
                    key={item.href + item.title}
                    className={`children_menu_navbar`}
                    target={item.target ? item.target : "_self"}
                  >
                    {item.title}
                  </NavLink>
                ))}
          </div>
        )
      ))}
    </Box>
  );
};

export default Sidebar;
