import { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import routes from "./routes/routes";
import Template from "./components/Template/Template";
import Login from "./Page/Login/Login";
import AuthService from "./services/auth/AuthService";
import RouterType from "./types/router/RouterType";
import { ChildrenUserModulesType, UserModulesType } from "./types/users/UserModulesType";

const App: React.FC = () => {
  const userAuth = AuthService.check();

  useEffect(() => {
    const pathName = window.location.pathname;
    if (!userAuth && pathName !== "/") {
      window.location.replace("/");
    }
  }, []);

  const user = AuthService.user();

  const hrfs: string[] = [];
  user?.permissoes?.forEach((item: UserModulesType) => {
    if (item.title.includes("DASHBOARD")) {
      hrfs.push("/dashboard");
    }
    item.children.forEach((children: ChildrenUserModulesType) => hrfs.push(children.href))
  });

  const filterRouter = (router: RouterType) => {
    if (user?.type.includes("painel")) {
      return true;
    } else {
      const pathFormatted = router.path.replace("/form", "").replace("/:id?", "");
      return hrfs.includes(pathFormatted);
    }
  }

  return (
    <Router>
      {
        userAuth ? (
          <Template>
            <Suspense fallback={<div>...</div>}>
              <Switch>
                {routes.filter(filterRouter).map((route) => (
                  <Route
                    key={"route_" + route.path}
                    path={route.path}
                    component={route.component}
                  />
                ))}
              </Switch>
            </Suspense>
          </Template>
        ) : (
          <Login />
        )
      }
    </Router>
  );
};

export default App;
