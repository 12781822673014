import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@material-ui/core";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';

import logoGuiaTransfer from "../../img/LOGO_GUIA_TRANSFER.svg";
import loginImg from "../../img/log.png";
import { Image } from "react-bootstrap";

import Sidebar from "./Sidebar";
import "./Template.css";
import "../../App.css";
import { ToastContainer } from "react-toastify";
import { FiLogOut } from "react-icons/fi";
import AuthService from "../../services/auth/AuthService";

type ContainerProps = {
  children: React.ReactNode;
};

const drawerWidth = 270;
const settings = ['Não há notificações'];

const Template: React.FC<ContainerProps> = (props: ContainerProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [mobile, setMobile] = useState<boolean>(false);

  const localstr: any = window.localStorage.getItem('auth')
  const jsonFormt = JSON.parse(localstr)

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const checkMobile = () => {
    const widthWindow = window.innerWidth;
    if (widthWindow <= 900) {
      setOpen(false);
      setMobile(true);
    } else {
      setOpen(true);
      setMobile(false);
    }
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);
  }, []);

  return (
    <>
      <Box sx={{ display: "flex" }} className="component_navbar">
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar className="bg-white">
            {mobile ? (
              <IconButton
                edge="start"
                color="default"
                aria-label="open drawer"
                onClick={() => setOpen(!open)}
              >
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            ) : (
              <img src={logoGuiaTransfer} alt="Logo GriaTransfer" />
            )}
            <div className="iconGroupHeader" >
              <IconButton color="default" onClick={() => AuthService.logout()}>
                <FiLogOut />
              </IconButton>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Notificações">
                  <IconButton onClick={handleOpenUserMenu}>
                    <NotificationsNoneOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  style={{ marginTop: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseNavMenu}>
                      <Typography style={{ textAlign: "center" }}>{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <div>
                <Image
                  width={34}
                  height={34}
                  roundedCircle
                  src={jsonFormt.foto === "" ? loginImg : jsonFormt.foto}
                  className="imgStyle"
                />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="persistent"
          open={open}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Sidebar />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="content">
          <Toolbar />
          <ToastContainer autoClose={2000} />
          {props.children}
        </Box>
      </Box>
    </>
  );
};

export default Template;
