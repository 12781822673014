import React, { useState } from 'react';

import './login.css';
import { Grid } from "@material-ui/core";
import logo from "../../img/LOGO_GUIA_TRANSFER.svg"
import ButtonIC from "../../shared/components/buttons/button";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import AuthService from '../../services/auth/AuthService';
import { LoginType } from '../../types/auth/AuthType';
import { UserModulesType } from '../../types/users/UserModulesType';
import env from '../../utils/env';

const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fields, setFields] = useState({
    login: "" as string,
    password: "" as string
  });

  const validation = (): boolean => {
    const validations = [
      {
        validation: () => fields.login.trim() === "",
        message: "O CAMPO EMAIL É OBRIGATÓRIO."
      },
      {
        validation: () => fields.password.trim() === "",
        message: "O CAMPO SENHA É OBRIGATÓRIO."
      }
    ];

    let i = 0;
    let valid = true;
    while (i < validations.length && valid) {
      if (validations[i]?.validation()) {
        toast.info(validations[i]?.message);
        valid = false;
      }
      i++;
    }

    return valid;
  }

  const authenticate = () => {
    if (validation()) {
      setLoading(true);

      const dataForm: LoginType = {
        login: fields.login,
        senha: fields.password
      }

      AuthService.login(dataForm)
        .then(() => {
          const user = AuthService.user();
          const permission = user?.permissoes?.find((item: UserModulesType) => item.title === "DASHBOARD");
          if (user?.type.includes("painel") || permission) {
            window.location.replace("/dashboard");
          } else {
            const lengthPermissions = user?.permissoes?.[0]?.children?.length ?? 0;

            let i = 0;
            while (i < lengthPermissions) {
              const href = user?.permissoes?.[0].children?.[i].href;
              if (href && !href.includes("http")) {
                window.location.replace(href);
              }
              i++;
            }
          }
        })
        .catch((error: Error) => toast.info(error.message))
        .finally(() => setLoading(false));
    }
  }

  const handleField = (nameField: string, value: string) => {
    setFields({ ...fields, [nameField]: value });
  }

  return (
    <form>
      <main className="app-main">
        <ToastContainer autoClose={2000} />
        <img src={logo} className="app-logo" alt="logo" width="auto" height="30" />
        <label className="label_logo">Console do Administrador</label>
        <Grid
          container
          direction="column"
          className="div-content"
          alignItems="flex-start"
        >
          <h5 className="title">Entre na sua conta</h5>
          <Grid item className="w-100">
            <Form.Group controlId="email" className="mb-4">
              <Form.Control
                type="email"
                value={fields.login}
                onChange={(event) => {
                  handleField("login", event.target.value);
                }}
                placeholder="Seu e-mail"
                className="edtUser"
                autoComplete="off"
                id="inputEmail"
              />
            </Form.Group>
          </Grid>
          <Grid item className="w-100">
            <Form.Group controlId="password" className="mb-4">
              <Form.Control
                type="password"
                value={fields.password}
                onChange={(event) => {
                  handleField("password", event.target.value);
                }}
                placeholder="Sua senha"
                className="edtUser"
              />
            </Form.Group>
          </Grid>
          <Grid item className="w-100">
            <ButtonIC
              className="button-login"
              disabled={loading}
              onClick={(event) => {
                event.preventDefault();
                authenticate();
              }}
            >
              {loading ? "CARREGANDO" : "ENTRAR"}
            </ButtonIC>
          </Grid>
          <Grid item>
            <a
              className="forget_password"
              href="rememberPassword"
              onClick={(event) => event.preventDefault()}
            >
              ESQUECEU A SENHA?
            </a>
          </Grid>

        </Grid>
        <Grid
          hidden={!(process.env.NODE_ENV == 'development')}
          container
          className="div-debug"
          direction="column"
          alignItems="flex-start">
          <Grid item>
            Debug info
            <br />
            <small className='tiny'>
              API: {env.apiUrl}
            </small>
          </Grid>
        </Grid>
      </main>
    </form>
  );
}
export default Login;
